import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "../../common/api";
import { invoiceStatusString, statusString } from "../../common/constants";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";
import { numberWithCommas } from "../../common/utils/format";
import {
  Group,
  Invoices,
  PaymentInfomation,
  RefundPolicy,
  Reservation,
} from "../../common/types";

import { useAppLinkContext } from "../../modules/useAppLink";

import Timer from "../../components/Timer";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Divider from "../../layouts/Divider";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";

import _ from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceDetail() {
  const { trackEvent } = useAmplitudeTrack();
  const { openAppLink } = useAppLinkContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");

  const [appDownloadBottomSheetOpened, setAppDownloadBottomSheetOpened] =
    useState(true);
  const [infoVisible, setInfoVisible] = useState(true);
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);

  const [group, setGroup] = useState<Group>();
  const [reservation, setReservation] = useState<Reservation>();
  const [invoice, setInvoice] = useState<Invoices>();
  const [paymentInfomation, setPaymentInfomation] =
    useState<PaymentInfomation>();
  const [refundPolicy, setRefundPolicy] = useState<RefundPolicy[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/ext/invoice?key=${key}`);

        setGroup(res.group);
        setReservation(res.reservation);
        setPaymentInfomation(res.paymentInfomation);
        setRefundPolicy(res.refundPolicy);

        if (res.invoices.length > 0) {
          setInvoice(res.invoices[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [key]);

  const divRef = useRef<HTMLDivElement>(null);
  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
          paddingBottom: 60,
          marginTop: 76,
        }}
      >
        <VFlex>
          <VFlex
            ref={divRef}
            bc-ww
            sdw-1={headerFixed}
            style={{
              width: "100%",
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              margin: "0 auto",
            }}
          >
            <HFlex a-c p-24 g-16>
              <SvgIcon
                size={24}
                icon={SvgIconType.Back}
                onClick={() => {
                  navigate(`/reservation?key=${key}`);
                }}
              />
              <VFlex t-22-600-s8>
                결제
                {invoice?.status === "EXPIRED" ||
                invoice?.status === "CANCELED_REQUEST"
                  ? "하기"
                  : "상세"}
              </VFlex>
            </HFlex>
          </VFlex>

          <VFlex p-16-b p-24-rl g-16>
            <VFlex g-4 t-14-s4>
              <HFlex g-12>
                <VFlex>
                  {`${
                    invoice?.status === "PAID"
                      ? "결제"
                      : invoice?.status === "U_CANCELED" ||
                        invoice?.status === "B_CANCELED"
                      ? "환불"
                      : "요청"
                  } 일시`}
                </VFlex>
                <VFlex>
                  {invoice &&
                    format(
                      invoice.status === "PAID"
                        ? invoice.paymentCompleted * 1000
                        : invoice.status === "U_CANCELED" ||
                          invoice.status === "B_CANCELED"
                        ? invoice.cancelCompleted * 1000
                        : invoice.issueDate * 1000,
                      "yyyy.MM.dd. HH:mm:ss"
                    )}
                </VFlex>
              </HFlex>
              <HFlex g-12>
                <VFlex>예약 번호</VFlex>
                <VFlex>{reservation?.reservationNumber}</VFlex>
              </HFlex>
            </VFlex>

            {(invoice?.status === "EXPIRED" ||
              invoice?.status === "CANCELED_REQUEST") && (
              <VFlex>
                <HFlex
                  ovf-h
                  p-16
                  g-12
                  a-s
                  bdr-12
                  style={{
                    border: "1px solid rgba(255, 79, 23, 0.30)",
                    background: "rgba(255, 79, 23, 0.10)",
                  }}
                >
                  <SvgIcon size={24} icon={SvgIconType.Info} />
                  {invoice?.status === "CANCELED_REQUEST" ? (
                    <VFlex g-4>
                      <VFlex t-14-600-s8 l-n>
                        결제 요청이 취소되었습니다.
                      </VFlex>
                      {invoice && (
                        <VFlex t-14-400-s7>
                          결제 취소 일시 :{/* TODO  결제 취소 일시 */}
                          {format(
                            new Date(invoice.expiredDate * 1000),
                            " yy.MM.dd  HH:mm",
                            {
                              locale: ko,
                            }
                          )}
                        </VFlex>
                      )}
                    </VFlex>
                  ) : (
                    <VFlex g-4>
                      <VFlex t-14-600-s8 l-n>
                        기한 만료로 예약이 취소되었습니다.
                      </VFlex>
                      {invoice && (
                        <HFlex g-4 a-c>
                          <VFlex t-14-400-s7>
                            {format(
                              new Date(invoice.expiredDate * 1000),
                              "yy.MM.dd(EEE) a h:mm",
                              {
                                locale: ko,
                              }
                            )}
                          </VFlex>
                          <Timer
                            style={{
                              color: "#FF4F17",
                            }}
                            time={
                              invoice.expiredDate * 1000 - new Date().getTime()
                            }
                          />
                        </HFlex>
                      )}
                    </VFlex>
                  )}
                </HFlex>
              </VFlex>
            )}
          </VFlex>

          <Divider height={12} />

          <VFlex g-24 p-24>
            <HFlex
              a-c
              j-b
              g-16
              onClick={() => {
                setInfoVisible((prev) => !prev);
              }}
            >
              <VFlex t-22-600-s8>예약 정보</VFlex>
              <HFlex
                a-c
                g-4
                style={{
                  maxWidth: 196,
                }}
              >
                <SvgIcon
                  size={24}
                  icon={infoVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown}
                />
              </HFlex>
            </HFlex>
            {infoVisible && (
              <VFlex bd-t4 bdr-16>
                <HFlex p-16 g-16 j-b a-c bd-b-t4>
                  <HFlex g-12 a-c>
                    {group?.attachment ? (
                      <Image
                        bdr-12
                        ovf-h
                        size={36}
                        src={group.attachment.path}
                      />
                    ) : (
                      <SvgIcon size={36} icon={SvgIconType.AvatarDefault} />
                    )}
                    <VFlex t-18-600-s8>{group?.title}</VFlex>
                  </HFlex>
                  <VFlex
                    p-8-tb
                    p-16-rl
                    bd-t5
                    bdr-12
                    t-14-600-s8
                    clickable
                    onClick={() => {
                      window.location.href = `tel://${group?.contact}`;
                    }}
                  >
                    문의하기
                  </VFlex>
                </HFlex>

                <VFlex p-16 g-16>
                  <VFlex g-12>
                    <VFlex
                      t-18-600-gr5={invoice?.status === "PAID"}
                      t-18-600-rd1={invoice?.status !== "PAID"}
                    >
                      {invoiceStatusString.find(
                        (item) => item.status === invoice?.status
                      )?.text || ""}
                    </VFlex>

                    {invoice?.status === "B_CANCELED" && (
                      <VFlex p-12-tb p-16-rl bc-t1 bdr-8 g-4>
                        <Text t-16-600-s6>업체의 결제 취소(환불) 처리</Text>
                        <Text t-14-600-s4>
                          취소/환불 규정에 따라 {paymentInfomation?.refundRate}%
                          환불되었습니다.
                        </Text>
                      </VFlex>
                    )}

                    {invoice?.status === "U_CANCELED" && (
                      <VFlex p-12-tb p-16-rl bc-t1 bdr-8 g-4>
                        <Text t-16-600-s6>고객의 예약 취소</Text>
                        <Text t-14-600-s4>
                          취소/환불 규정에 따라 {paymentInfomation?.refundRate}%
                          환불되었습니다.
                        </Text>
                      </VFlex>
                    )}

                    {invoice?.status === "EXPIRED" && (
                      <VFlex p-12-tb p-16-rl bc-t1 bdr-8 g-4>
                        <Text t-16-600-s6>기한 만료로 인한 결제 요청 취소</Text>
                      </VFlex>
                    )}

                    {invoice?.status === "CANCELED_REQUEST" && (
                      <VFlex p-12-tb p-16-rl bc-t1 bdr-8 g-4>
                        <Text t-16-600-s6>업체의 결제 요청 취소 처리</Text>
                      </VFlex>
                    )}

                    <VFlex g-4>
                      <HFlex g-8>
                        <VFlex t-16-600-s8>
                          {`${reservation?.pet.name}(${reservation?.pet.species.name})`}
                        </VFlex>
                        <VFlex p-8-rl c-c bd-t4 bdr-8 t-14-s6>
                          {reservation && statusString[reservation.status]}
                        </VFlex>
                      </HFlex>
                      <HFlex
                        g-8
                        t-16-s4
                        a-c
                        style={{
                          flexWrap: "wrap",
                        }}
                      >
                        <VFlex>{reservation?.reservationPeriod}</VFlex>
                        <VFlex
                          style={{
                            width: 1,
                            height: 12,
                            background: "#CAD1CE",
                          }}
                        />
                        {invoice?.type === "RESV_DEPOSIT" ? (
                          <VFlex>예약금</VFlex>
                        ) : (
                          <VFlex l-n>
                            {invoice?.products[0].name}
                            {reservation &&
                              reservation.products.length > 1 &&
                              ` 외 ${reservation.products.length - 1}건`}
                          </VFlex>
                        )}
                      </HFlex>
                    </VFlex>
                    <HFlex
                      a-c
                      g-4
                      onClick={() => {
                        setMoreInfoVisible((prev) => !prev);
                      }}
                    >
                      <VFlex t-20-600-s8>
                        {numberWithCommas(invoice?.actualAmount ?? 0)}원
                      </VFlex>
                      <SvgIcon
                        size={24}
                        icon={
                          moreInfoVisible
                            ? SvgIconType.ArrUp
                            : SvgIconType.ArrDown
                        }
                      />
                    </HFlex>
                  </VFlex>

                  {moreInfoVisible && (
                    <VFlex>
                      <VFlex p-16 g-12 bc-t1 bdr-12>
                        <VFlex g-4 p-12-b bd-b-s8>
                          {reservation?.products.map((p) => {
                            return (
                              <HFlex key={p.id} g-16 j-b>
                                <VFlex t-14-s4>
                                  {p.name}
                                  {p.quantity > 1 && `*${p.quantity}`}
                                </VFlex>
                                <VFlex t-14-s8>
                                  {numberWithCommas(
                                    p.quantity > 1
                                      ? p.price * p.quantity
                                      : p.price
                                  )}
                                  원
                                </VFlex>
                              </HFlex>
                            );
                          })}
                          {invoice?.extraAmount ? (
                            <HFlex g-16 j-b>
                              <VFlex t-14-s4>가격 추가</VFlex>
                              <VFlex t-14-600-bl1>
                                +{numberWithCommas(invoice.extraAmount)}원
                              </VFlex>
                            </HFlex>
                          ) : null}
                          {invoice?.discountAmount ? (
                            <HFlex g-16 j-b>
                              <VFlex t-14-s4>가격 할인</VFlex>
                              <VFlex t-14-600-bl1>
                                -{numberWithCommas(invoice.discountAmount)}원
                              </VFlex>
                            </HFlex>
                          ) : null}
                        </VFlex>
                        <VFlex
                          g-4
                          p-16-b={invoice?.type === "RESV_DEPOSIT"}
                          bd-b-t4={invoice?.type === "RESV_DEPOSIT"}
                        >
                          <HFlex g-16 j-b a-c>
                            <VFlex t-14-600-s8>상품 금액</VFlex>
                            <VFlex t-14-600-s8>
                              {numberWithCommas(invoice?.finalAmount ?? 0)}원
                            </VFlex>
                          </HFlex>
                        </VFlex>
                        {invoice?.type === "RESV_DEPOSIT" && (
                          <VFlex g-4>
                            <HFlex g-16 j-b a-c>
                              <VFlex t-14-600-s8>예약금</VFlex>
                              <VFlex t-14-600-gr5>
                                {numberWithCommas(invoice.actualAmount ?? 0)}원
                              </VFlex>
                            </HFlex>
                            <VFlex a-e t-14-s6>
                              (상품 금액의 {invoice?.rate}%)
                            </VFlex>
                          </VFlex>
                        )}
                      </VFlex>
                    </VFlex>
                  )}

                  <VFlex
                    a-c
                    p-12-tb
                    p-24-rl
                    bd-t5
                    bdr-12
                    t-16-600-s8
                    clickable
                    onClick={() => {
                      navigate(`/reservation?key=${key}`);
                    }}
                  >
                    예약 보기
                  </VFlex>
                </VFlex>
              </VFlex>
            )}
          </VFlex>

          <Divider height={12} />

          {invoice?.status !== "EXPIRED" &&
          invoice?.status !== "CANCELED_REQUEST" ? (
            <>
              <VFlex>
                <VFlex p-24 t-22-600-s8 bd-b-t2>
                  결제 정보
                </VFlex>
                <VFlex p-24 g-16>
                  {invoice?.type === "RESV_DEPOSIT" ? (
                    <HFlex g-16 bd-b-t4 p-16-b j-b>
                      <VFlex t-16-s4>예약금</VFlex>
                      <VFlex t-16-s8>
                        {numberWithCommas(invoice?.actualAmount ?? 0)}원
                      </VFlex>
                    </HFlex>
                  ) : (
                    <VFlex g-8 bd-b-t4>
                      <HFlex g-16 j-b p-16-b>
                        <VFlex t-16-s4>상품 금액</VFlex>
                        <VFlex t-16-s8>
                          {numberWithCommas(invoice?.actualAmount ?? 0)}원
                        </VFlex>
                      </HFlex>
                      {/* <HFlex g-16 p-16-b j-b>
                        <VFlex t-16-s4>적립금 사용</VFlex>
                        <VFlex t-16-600-bl1>
                          -
                          {numberWithCommas(
                            paymentInfomation?.usedGroupMileage ?? 0
                          )}
                          원
                        </VFlex>
                      </HFlex> */}
                    </VFlex>
                  )}
                  {invoice?.status === "B_CANCELED" ||
                  invoice?.status === "U_CANCELED" ? (
                    <>
                      <HFlex g-16 bd-b-t4 p-16-b j-b>
                        <VFlex t-16-s4>최초 결제 금액</VFlex>
                        <VFlex t-16-s8>
                          {numberWithCommas(invoice?.actualAmount ?? 0)}원
                        </VFlex>
                      </HFlex>
                      <VFlex g-8>
                        <HFlex g-16 j-b>
                          <VFlex t-18-600-s8>환불 금액</VFlex>
                          <VFlex t-20-600-rd1>
                            {paymentInfomation?.refundAmount === 0 ? "" : "-"}
                            {numberWithCommas(
                              paymentInfomation?.refundAmount ?? 0
                            )}
                            원({paymentInfomation?.refundRate}%)
                          </VFlex>
                        </HFlex>
                        <HFlex g-16 j-b>
                          <VFlex t-16-s4>환불 수단</VFlex>
                          <VFlex t-16-s8>
                            {paymentInfomation?.refundMethodNumber}
                          </VFlex>
                        </HFlex>
                        <HFlex g-16 j-b>
                          <VFlex t-16-s4>환불 일시</VFlex>
                          <VFlex t-16-s8>
                            {paymentInfomation?.refundCompleted &&
                              format(
                                paymentInfomation?.refundCompleted * 1000,
                                "yyyy.MM.dd HH:mm"
                              )}
                          </VFlex>
                        </HFlex>
                      </VFlex>
                    </>
                  ) : (
                    <VFlex g-8>
                      <HFlex g-16 j-b>
                        <VFlex t-18-600-s8>최종 결제 금액</VFlex>
                        <VFlex t-20-600-gr5>
                          {numberWithCommas(invoice?.actualAmount ?? 0)}원
                        </VFlex>
                      </HFlex>
                      <HFlex g-16 j-b>
                        <VFlex t-16-s4>결제 수단</VFlex>
                        <VFlex t-16-s8>
                          {paymentInfomation?.paymentMethodNumber}
                        </VFlex>
                      </HFlex>
                      <HFlex g-16 j-b>
                        <VFlex t-16-s4>결제 일시</VFlex>
                        <VFlex t-16-s8>
                          {paymentInfomation &&
                            paymentInfomation?.paymentCompleted &&
                            format(
                              paymentInfomation?.paymentCompleted * 1000,
                              "yyyy.MM.dd HH:mm"
                            )}
                        </VFlex>
                      </HFlex>
                    </VFlex>
                  )}
                  <VFlex
                    a-c
                    p-12-tb
                    p-24-rl
                    bd-t5
                    bdr-12
                    t-16-600-s8
                    clickable
                    onClick={() => {
                      navigate(`/invoicereceipt`);
                    }}
                  >
                    카드 영수증 보기
                  </VFlex>
                </VFlex>

                {(invoice?.status === "B_CANCELED" ||
                  invoice?.status === "U_CANCELED") && (
                  <VFlex c-c t-14-600-s4 p-24-b>
                    취소 및 환불까지 영업일 기준 3~5일이 걸립니다.
                  </VFlex>
                )}
              </VFlex>

              <Divider height={12} />
            </>
          ) : null}

          <VFlex>
            <VFlex p-24 t-22-600-s8 bd-b-t2>
              {invoice?.type === "RESV_DEPOSIT" ? "예약금" : "결제"} 안내사항
            </VFlex>
            <VFlex p-24 t-16-s6 l-n>
              {invoice?.noticeMessage}
            </VFlex>
          </VFlex>

          <Divider height={12} />

          <VFlex>
            <VFlex p-24 t-22-600-s8 bd-b-t2>
              환불 정책
            </VFlex>
            <VFlex p-24>
              <VFlex bd-t-t5 bd-b-t5>
                {refundPolicy?.map((refund: RefundPolicy, i: number) => {
                  const parts = refund.refundRate.split(
                    new RegExp(`(${refund.rate}%)`)
                  );
                  return (
                    <HFlex
                      a-c
                      bd-b-t5={i !== refundPolicy.length - 1}
                      key={refund.period}
                    >
                      <VFlex f-1 p-12-tb p-16-rl bc-t2 bd-r-t5 t-14-600-s7>
                        {refund.cancelDate}
                      </VFlex>
                      <HFlex f-1 p-12-tb p-16-rl g-4>
                        {parts.map((part, index) => (
                          <VFlex
                            t-14-600-rd1={part === `${refund.rate}%`}
                            t-14-400-s4={part !== `${refund.rate}%`}
                            key={index}
                          >
                            {part}
                          </VFlex>
                        ))}
                      </HFlex>
                    </HFlex>
                  );
                })}
              </VFlex>
            </VFlex>
          </VFlex>

          <Divider height={12} />

          <VFlex p-24>
            <VFlex
              f-1
              a-c
              p-16
              bd-t5
              bdr-12
              t-14-600-s8
              clickable
              onClick={() => {
                window.open("https://pawdly.channel.io/lounge", "_blank");
              }}
            >
              고객센터 문의하기
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
