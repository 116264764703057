import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../common/api";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceReceipt() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
        }}
      >
        <HFlex a-c p-24 g-16>
          <SvgIcon
            size={24}
            icon={SvgIconType.Back}
            onClick={() => {
              navigate(-1);
            }}
          />
          <VFlex t-22-600-s8>카드 영수증</VFlex>
        </HFlex>

        <VFlex p-24>
          <VFlex g-24 bd-b-t2 p-24-b>
            <VFlex t-20-600-s8>결제 정보</VFlex>
            <VFlex g-10>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드종류/승인번호</VFlex>
                <VFlex t-16-s8>국민 / 1245212</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드종류/승인번호</VFlex>
                <VFlex t-16-s8>국민 / 1245212</VFlex>
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex g-24 bd-b-t2 p-24-tb>
            <VFlex t-20-600-s8>결제 정보</VFlex>
            <VFlex g-10>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드종류/승인번호</VFlex>
                <VFlex t-16-s8>국민 / 1245212</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드종류/승인번호</VFlex>
                <VFlex t-16-s8>국민 / 1245212</VFlex>
              </HFlex>
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
