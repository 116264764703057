import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "../../common/api";
import { Invoices, PaymentInfomation, Reservation } from "../../common/types";
import { numberWithCommas } from "../../common/utils/format";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";

import { format } from "date-fns";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceComplete() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");
  const [reservation, setReservation] = useState<Reservation>();
  const [invoice, setInvoice] = useState<Invoices>();
  const [paymentInfomation, setPaymentInfomation] =
    useState<PaymentInfomation>();
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/ext/invoice?key=${key}`);
        setReservation(res.reservation);
        setPaymentInfomation(res.paymentInfomation);

        if (res.invoices.length > 0) {
          setInvoice(res.invoices[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [key]);

  useEffect(() => {
    if (paymentInfomation && !paymentInfomation.paymentMethodNumber) {
      return navigate(`/invoice?key=${key}`);
    }
  }, [paymentInfomation]);

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
        }}
      >
        <HFlex
          p-16-tb
          p-24-rl
          style={{
            justifyContent: "end",
          }}
          onClick={() => {
            navigate(`/reservation?key=${key}`);
          }}
        >
          <SvgIcon size={24} icon={SvgIconType.Close} />
        </HFlex>

        <VFlex a-c g-16 m-24-rl p-28-b>
          <SvgIcon size={56} icon={SvgIconType.Success} />
          {invoice?.type === "RESV_DEPOSIT" ? (
            <VFlex a-c t-22-600-s8>
              예약이 확정되었습니다.
            </VFlex>
          ) : (
            <VFlex a-c t-22-600-s8>
              결제가 완료되었습니다.
            </VFlex>
          )}
        </VFlex>

        <VFlex g-24 p-24-brl>
          <VFlex bd-t4 bdr-16>
            <HFlex a-c g-12 p-16 bc-t1 bd-b-t4>
              <Text t-16-600-s8>예약 번호</Text>
              <Text t-16-s4>{reservation?.reservationNumber}</Text>
            </HFlex>

            <VFlex>
              <VFlex p-16 g-12>
                <VFlex g-4>
                  <VFlex t-16-600-s8>
                    {`${reservation?.pet.name}(${reservation?.pet.species.name})`}
                  </VFlex>
                  <HFlex
                    g-8
                    t-16-s4
                    a-c
                    style={{
                      flexWrap: "wrap",
                    }}
                  >
                    <VFlex>{reservation?.reservationPeriod}</VFlex>
                    <VFlex
                      style={{
                        width: 1,
                        height: 12,
                        background: "#CAD1CE",
                      }}
                    />
                    {invoice?.type === "RESV_DEPOSIT" ? (
                      <VFlex>예약금</VFlex>
                    ) : (
                      <VFlex l-n>
                        {invoice?.products[0].name}
                        {reservation &&
                          reservation.products.length > 1 &&
                          ` 외 ${reservation.products.length - 1}건`}
                      </VFlex>
                    )}
                  </HFlex>
                </VFlex>
                <HFlex
                  a-c
                  g-4
                  onClick={() => {
                    setMoreInfoVisible((prev) => !prev);
                  }}
                >
                  <VFlex t-20-600-s8>
                    {numberWithCommas(paymentInfomation?.actualAmount ?? 0)}원
                  </VFlex>
                  <SvgIcon
                    size={24}
                    icon={
                      moreInfoVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown
                    }
                  />
                </HFlex>
              </VFlex>

              {moreInfoVisible && (
                <VFlex p-16-brl>
                  <VFlex p-16 g-12 bc-t1 bdr-12>
                    <VFlex g-4 p-12-b bd-b-s8>
                      {invoice?.products.map(
                        (p: {
                          id: number;
                          price: number;
                          quantity: number;
                          name: string;
                        }) => {
                          return (
                            <HFlex key={p.id} g-16 j-b>
                              <VFlex t-14-s4>{p.name}</VFlex>
                              <VFlex t-14-s8>
                                {numberWithCommas(p.price ?? 0)}원
                              </VFlex>
                            </HFlex>
                          );
                        }
                      )}
                      {invoice?.extraAmount ? (
                        <HFlex g-16 j-b>
                          <VFlex t-14-s4>가격 추가</VFlex>
                          <VFlex t-14-600-bl1>
                            +{numberWithCommas(invoice.extraAmount)}원
                          </VFlex>
                        </HFlex>
                      ) : null}
                      {invoice?.discountAmount ? (
                        <HFlex g-16 j-b>
                          <VFlex t-14-s4>가격 할인</VFlex>
                          <VFlex t-14-600-bl1>
                            -{numberWithCommas(invoice.discountAmount)}원
                          </VFlex>
                        </HFlex>
                      ) : null}
                    </VFlex>
                    <VFlex
                      g-4
                      p-16-b={invoice?.type === "RESV_DEPOSIT"}
                      bd-b-t4={invoice?.type === "RESV_DEPOSIT"}
                    >
                      <HFlex g-16 j-b a-c>
                        <VFlex t-14-600-s8>상품 금액</VFlex>
                        <VFlex t-14-600-s8>
                          {numberWithCommas(invoice?.finalAmount ?? 0)}원
                        </VFlex>
                      </HFlex>
                    </VFlex>
                    {invoice?.type === "RESV_DEPOSIT" && (
                      <VFlex g-4>
                        <HFlex g-16 j-b a-c>
                          <VFlex t-14-600-s8>예약금</VFlex>
                          <VFlex t-14-600-gr5>
                            {numberWithCommas(invoice?.actualAmount ?? 0)}원
                          </VFlex>
                        </HFlex>
                        <VFlex a-e t-14-s6>
                          (상품 금액의 {invoice?.rate}%)
                        </VFlex>
                      </VFlex>
                    )}
                  </VFlex>
                </VFlex>
              )}

              <VFlex p-16 g-8 bd-t-t4>
                <HFlex g-16 j-b>
                  <VFlex t-16-600-s8>결제 금액</VFlex>
                  <VFlex t-18-600-gr5>
                    {numberWithCommas(invoice?.actualAmount ?? 0)}원
                  </VFlex>
                </HFlex>
                {invoice?.type === "RESV_DEPOSIT" ? (
                  <HFlex g-16 j-b>
                    <VFlex t-16-s4>예약금</VFlex>
                    <VFlex t-16-s8>
                      {numberWithCommas(invoice?.actualAmount ?? 0)}원
                    </VFlex>
                  </HFlex>
                ) : (
                  <VFlex g-8>
                    <HFlex g-16 j-b>
                      <VFlex t-16-s4>상품 금액</VFlex>
                      <VFlex t-16-s8>
                        {numberWithCommas(invoice?.actualAmount ?? 0)}원
                      </VFlex>
                    </HFlex>
                    {/* <HFlex g-16 j-b>
                      <VFlex t-16-s4>적립금 사용</VFlex>
                      <VFlex t-16-600-bl1>
                        -
                        {numberWithCommas(
                          paymentInfomation?.usedGroupMileage ?? 0
                        )}
                        원
                      </VFlex>
                    </HFlex> */}
                  </VFlex>
                )}
              </VFlex>

              <VFlex p-16 g-8 bd-t-t4>
                <HFlex g-16 j-b>
                  <VFlex t-16-s4>결제 수단</VFlex>
                  <VFlex t-16-s8>
                    {paymentInfomation?.paymentMethodNumber}
                  </VFlex>
                </HFlex>
                <HFlex g-16 j-b>
                  <VFlex t-16-s4>결제 일시</VFlex>
                  <VFlex t-16-s8>
                    {paymentInfomation &&
                      paymentInfomation?.paymentCompleted &&
                      format(
                        paymentInfomation?.paymentCompleted * 1000,
                        "yyyy.MM.dd HH:mm"
                      )}
                  </VFlex>
                </HFlex>
              </VFlex>
            </VFlex>
          </VFlex>
        </VFlex>

        <VFlex
          p-24-trl
          bc-t1
          t-14-s4
          style={{
            paddingBottom: 240,
          }}
        >
          <VFlex l-n>• 예약 변경을 원하는 경우 업체에 문의해주세요.</VFlex>
          {/* <VFlex l-n>• 결제 취소시 적립금이 회수될 수 있습니다.</VFlex> */}
        </VFlex>
      </VFlex>

      <VFlex
        p-16-tb
        p-24-rl
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          p-16-tb
          bdr-16
          t-18-600-ww
          bc-pt
          onClick={() => {
            navigate(`/invoicedetail?key=${key}`);
          }}
        >
          결제 상세 보기
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
